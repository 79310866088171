<template>
  <aplayer
  :music="{
    title: getPlaylistFromFiles[0].title,
    src: getPlaylistFromFiles[0].src,
    artist: getPlaylistFromFiles[0].extension,
    pic: this.fileGroupInfo.preview_file,
  }"
  :list="getPlaylistFromFiles"
  class="custom-aplayer"
  />
</template>

<style lang="scss" scoped>
  @import './AudioPlayer.scss';
</style>

<script>
import Aplayer from 'vue-aplayer';

export default {
  components: {
    Aplayer,
  },
  props: {
    fileGroupInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    getPlaylistFromFiles() {
      return this.fileGroupInfo.displayFiles.map(file => ({
        title: file.name,
        src: file.file,
        artist: file.extension,
        pic: this.fileGroupInfo.preview_file,
      }));
    },
  },
};
</script>
